export const locKeys = {
  roomPickerOpened: "wizard.roomPicker.roomPickerOpened.liveAnnounce",
  roomAdded: "wizard.roomPicker.roomAdded.liveAnnounce",
  roomRemoved: "wizard.roomPicker.roomRemoved.liveAnnounce",
  doneLabel: "wizard.roomPicker.doneButton.label",
  addRoom: "wizard.roomPicker.addRoomButton.label",
  removeRoom: "wizard.roomPicker.removeRoomButton.label",
  removeRoomAria: "wizard.roomPicker.removeRoomButton.a11yLabel",
  childAgeLabel: "wizard.roomPicker.childAgeInput.label",
  infantAgeLabel: "wizard.roomPicker.infantAgeInput.label",
  roomHeaderTitle: "wizard.roomPicker.room.title",
  roomHeaderAriaLabel: "wizard.roomPicker.room.a11yLabel",
  childUpdated: "wizard.roomPicker.childUpdated.liveAnnounce",
  infantUpdated: "wizard.roomPicker.infantUpdated.liveAnnounce",
  decreaseAdults: "wizard.roomPicker.decreaseAdults.text",
  decreaseChildren: "wizard.roomPicker.decreaseChildren.text",
  decreaseInfants: "wizard.roomPicker.decreaseInfants.text",
  decreaseAdultsInRoom: "wizard.roomPicker.decreaseAdultsInRoom.text",
  decreaseChildrenInRoom: "wizard.roomPicker.decreaseChildrenInRoom.text",
  decreaseInfantsInRoom: "wizard.roomPicker.decreaseInfantsInRoom.text",
  adults: "wizard.roomPicker.adults.text",
  increaseAdults: "wizard.roomPicker.increaseAdults.text",
  increaseChildren: "wizard.roomPicker.increaseChildren.text",
  increaseInfants: "wizard.roomPicker.increaseInfant.text",
  increaseAdultsInRoom: "wizard.roomPicker.increaseAdultsInRoom.text",
  increaseChildrenInRoom: "wizard.roomPicker.increaseChildrenInRoom.text",
  increaseInfantInRoom: "wizard.roomPicker.increaseInfantInRoom.text",
  children: "wizard.roomPicker.children.text",
  infants: "wizard.roomPicker.infants.text",
  airlineChildSeatingMessage: "wizard.airline.children.seatRules",
  airlineChildSeatingMessagePlural: "wizard.airline.children.seatRulesPlural",
  airlineSeatingInSeat: "wizard.airline.children.seatOption.inSeat",
  airlineSeatingOnLap: "wizard.airline.children.seatOption.onLap",
  airlineAgeRules: "wizard.airline.ageRules.label",
};
