import * as React from "react";
import { TravelersContent } from "./TravelersContent";
import { TravelersDialogProps } from "../typings";
import { observer } from "mobx-react";

export const TravelersDialog: React.FunctionComponent<TravelersDialogProps> = observer(
  ({ travelers, onSave, onChange, config, lobState, setInputsRefs }) => {
    return (
      <TravelersContent
        travelers={travelers}
        onSave={onSave}
        onChange={onChange}
        config={config}
        lobState={lobState}
        setInputsRefs={setInputsRefs}
      />
    );
  }
);
