import * as React from "react";
import { observer, inject } from "mobx-react";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "../TravelersHiddenInputType";
import { TravelersHiddenInputsProps } from "../typings";

export const TravelersHiddenInputs = inject("context")(
  observer(
    ({
      hiddenInputType,
      flightSearch,
      hotwireFlightSearch,
      hotelSearch,
      hotwireHotelSearch,
      packageSearch,
      hotwirePackageSearch,
      cruiseSearch,
      thirdSearch,
      isApiCallEnabled,
      groundTransportSearch,
    }: TravelersHiddenInputsProps) => {
      switch (hiddenInputType) {
        case TRAVELERS_HIDDEN_INPUT_TYPE.GROUND_TRANSPORT_SEARCH:
          return (
            <>
              <input type="hidden" name="adults" value={groundTransportSearch.numOfAdults} data-testid="adults-input" />
              {groundTransportSearch.numOfChildren > 0 && (
                <input
                  type="hidden"
                  name="children"
                  value={groundTransportSearch.numOfChildren}
                  data-testid="children-input"
                />
              )}
              {groundTransportSearch.numOfInfants > 0 && (
                <input
                  type="hidden"
                  name="infants"
                  value={groundTransportSearch.numOfInfants}
                  data-testid="infants-input"
                />
              )}
            </>
          );
        case TRAVELERS_HIDDEN_INPUT_TYPE.FLIGHT_SEARCH:
          return <input type="hidden" name="passengers" value={flightSearch} data-testid="passengers-input" />;
        case TRAVELERS_HIDDEN_INPUT_TYPE.HOTWIRE_FLIGHT_SEARCH:
          return <input type="hidden" name="noOfTickets" value={hotwireFlightSearch} data-testid="noOfTickets-input" />;
        case TRAVELERS_HIDDEN_INPUT_TYPE.HOTEL_SEARCH:
          return (
            <>
              <input type="hidden" name="rooms" value={hotelSearch.rooms} data-testid="rooms-input" />
              <input type="hidden" name="adults" value={hotelSearch.adults} data-testid="adults-input" />
              {hotelSearch.children && (
                <input type="hidden" name="children" value={hotelSearch.children} data-testid="children-input" />
              )}
            </>
          );
        case TRAVELERS_HIDDEN_INPUT_TYPE.HOTWIRE_HOTEL_SEARCH:
          return (
            <>
              <input type="hidden" name="rooms" value={hotwireHotelSearch.rooms} data-testid="rooms-input" />
              <input type="hidden" name="adults" value={hotwireHotelSearch.adults} data-testid="adults-input" />
              {hotwireHotelSearch.children && (
                <input type="hidden" name="children" value={hotwireHotelSearch.children} data-testid="children-input" />
              )}
            </>
          );
        case TRAVELERS_HIDDEN_INPUT_TYPE.PACKAGE_SEARCH:
          const packageSearchInputs = [];
          for (const key of Object.keys(packageSearch)) {
            packageSearchInputs.push(
              <input type="hidden" name={key} key={key} value={packageSearch[key]} data-testid={`${key}-input`} />
            );
          }

          return <>{packageSearchInputs}</>;
        case TRAVELERS_HIDDEN_INPUT_TYPE.HOTWIRE_PACKAGE_SEARCH:
          return (
            <>
              {Object.keys(hotwirePackageSearch).map((key) => (
                <input
                  type="hidden"
                  name={key}
                  key={key}
                  value={hotwirePackageSearch[key]}
                  data-testid={`${key}-input`}
                />
              ))}
            </>
          );
        case TRAVELERS_HIDDEN_INPUT_TYPE.CRUISE_SEARCH:
          return (
            <>
              <input
                type="hidden"
                name="adultCount"
                value={cruiseSearch.numOfAdults}
                data-testid="cruise-adults-input"
              />
              {cruiseSearch.numOfChildren > 0 && (
                <input
                  type="hidden"
                  name="childCount"
                  value={cruiseSearch.numOfChildren}
                  data-testid="cruise-children-input"
                />
              )}
              {cruiseSearch.numOfChildren > 0 && (
                <input
                  type="hidden"
                  name="childAges"
                  value={cruiseSearch.childAges}
                  data-testid="cruise-childAges-input"
                />
              )}
            </>
          );
        case TRAVELERS_HIDDEN_INPUT_TYPE.THIRD_SEARCH:
          const threePPSearchInputs = [];
          threePPSearchInputs.push(
            <input
              type="hidden"
              name={!isApiCallEnabled ? "adult" : "adultCount"}
              value={thirdSearch.numOfAdults}
              data-testid="third-adult-input"
              key="third-adult-input"
            />
          );

          if (thirdSearch.numOfChildren > 0) {
            if (!isApiCallEnabled) {
              threePPSearchInputs.push(
                <input
                  type="hidden"
                  name="child"
                  value={thirdSearch.childAges}
                  data-testid="third-child-ages-input"
                  key="third-child-ages-input"
                />
              );
            } else {
              threePPSearchInputs.push(
                <input
                  type="hidden"
                  name="numChildren"
                  value={thirdSearch.numOfChildren}
                  data-testid="third-child-input"
                  key="third-child-input"
                />
              );
              (thirdSearch.childAges || "").split(",").forEach((value, index) => {
                threePPSearchInputs.push(
                  <input
                    type="hidden"
                    name="childAge"
                    value={value}
                    data-testid={`third-child-${index}-input`}
                    key={`third-child-${index}-input`}
                  />
                );
              });
            }
          }

          return <>{threePPSearchInputs}</>;
        default:
          return <></>;
      }
    }
  )
);
