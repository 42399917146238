import { TravelersFieldConfig } from "src/stores/wizard/config";
import { InfantSeating } from "./InfantSeating";
import { Dispatch, SetStateAction } from "react";
import { TRAVELERS_HIDDEN_INPUT_TYPE } from "./TravelersHiddenInputType";
import { AnalyticsStore } from "bernie-plugin-mobx";
import { LOBState } from "stores/wizard/state";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

export interface TravelersFieldProps {
  travelers: Travelers;
  metadata: TravelersMetadata;
  config: TravelersFieldConfig;
  onSave: (type: "SUBMIT" | "CHANGE", travelers: Travelers) => void;
  noRehydratationValues?: NoRehydratationValues;
  tripLobType?: string;
  setChange?: () => void;
  isChanged?: boolean;
  analytics?: AnalyticsStore;
  lobState: LOBState;
  triggerType?: TravelersTriggerTypes;
  isApiCallEnabled?: boolean;
  handleMenuTriggerState?: (menuState: boolean) => void;
  submitForm?: () => void;
}

export interface NoRehydratationValues {
  name: string;
  numOfTravelers: number;
}

export interface TravelersInputProps {
  name: string;
  travelers: Travelers;
  setTravelers: Dispatch<SetStateAction<Travelers>>;
  withRooms: boolean;
  withFlight: boolean;
  withInfants: boolean;
  config: TravelersFieldConfig;
  onSave: (travelers: Travelers) => void;
}

export interface TravelersDialogProps {
  travelers: Travelers;
  config: TravelersFieldConfig;
  onSave: (travelers: Travelers) => void;
  onChange: (travelers: Travelers) => void;
  lobState: LOBState;
  setInputsRefs?: (ref: React.RefObject<HTMLHeadingElement>) => void;
}

export interface Child {
  age: number;
}

export interface Infant {
  age: number;
}

export interface AgeOption {
  value: string;
  name: string;
}

export interface Room {
  adults: number;
  children: Child[];
  infants: Infant[];
}

export interface Travelers {
  rooms: Room[];
  infantSeating: InfantSeating;
}

export interface NonHotelTravelers {
  rooms: [Room]; // restrict to 1 room
  infantSeating: InfantSeating;
}

export interface TravelersContentProps {
  travelers: Travelers;
  config: TravelersFieldConfig;
  onChange: (travelers: Travelers) => void;
  onSave: (travelers: Travelers) => void;
  lobState: LOBState;
  setInputsRefs?: (ref: React.RefObject<HTMLHeadingElement>) => void;
}

export interface TravelersPickerProps extends TravelersContentProps {
  roomRefs: React.RefObject<HTMLHeadingElement>[];
  totalRoomRefs: number;
  setTotalRoomRefs: Dispatch<SetStateAction<number>>;
  setTravelers: Dispatch<SetStateAction<Travelers>>;
  analytics?: AnalyticsStore;
}

export interface RoomProps {
  setTravelers: Dispatch<SetStateAction<Travelers>>;
  travelers: Travelers;
  index: number;
  room: Room;
  config: TravelersFieldConfig;
  roomRefs: React.RefObject<HTMLHeadingElement>[];
  totalRoomRefs: number;
  setTotalRoomRefs: Dispatch<SetStateAction<number>>;
  childAgeOptions: AgeOption[];
  infantAgeOptions: AgeOption[];
  analytics?: AnalyticsStore;
  lobState: LOBState;
  context?: ExtendedContextStore;
}

// Hidden i/p fields for hotel search
export interface HotelSearch {
  rooms: string;
  adults: string;
  children?: string;
}

// Hidden i/p fields for package search
export interface PackageSearch {
  numberOfRooms: string;
  infantsInSeats: string;
  [key: string]: any;
}

// Hidden i/p fields for cruise search
export interface CruiseSearch {
  numOfAdults: number;
  numOfChildren: number;
  childAges?: string;
}

// Hidden i/p fields for threePP search
export interface ThirdSearch {
  numOfAdults: number;
  numOfChildren: number;
  childAges?: string;
}

export interface GroundTransportSearch {
  numOfAdults: number;
  numOfChildren: number;
  numOfInfants: number;
}

export interface TravelersMetadata {
  numOfAdults: number;
  numOfChildren: number;
  numOfInfants: number;
  numOfRooms: number;
  travelersAgeTwelveOrOlder: number;
  flightSearch: string;
  hotwireFlightSearch: number;
  hotelSearch: HotelSearch;
  hotwireHotelSearch: HotelSearch;
  packageSearch: PackageSearch;
  hotwirePackageSearch: PackageSearch;
  cruiseSearch: CruiseSearch;
  thirdSearch: ThirdSearch;
  groundTransportSearch: GroundTransportSearch;
}

export interface TravelersHiddenInputsProps {
  hiddenInputType: TRAVELERS_HIDDEN_INPUT_TYPE;
  flightSearch: string;
  hotwireFlightSearch: number;
  hotelSearch: HotelSearch;
  hotwireHotelSearch: HotelSearch;
  packageSearch: PackageSearch;
  hotwirePackageSearch: PackageSearch;
  cruiseSearch: CruiseSearch;
  thirdSearch: ThirdSearch;
  isApiCallEnabled?: boolean;
  groundTransportSearch: GroundTransportSearch;
}

export enum TravelersTriggerTypes {
  FAKE_INPUT = "fake input",
  LINK_BUTTON = "link button",
}
