import {
  CruiseSearch,
  GroundTransportSearch,
  HotelSearch,
  Room,
  ThirdSearch,
  Travelers,
  TravelersMetadata,
} from "./typings";
import { InfantSeating } from "./InfantSeating";
import { useLocalization } from "@shared-ui/localization-context";
import { TravelersFieldConfig } from "stores/wizard/config";

const buildHotwireFlightSearch = ({ numOfAdults, numOfChildren }: TravelersMetadata) => numOfAdults + numOfChildren;

const buildFlightSearch = (
  { numOfAdults, numOfChildren, numOfInfants }: TravelersMetadata,
  childrenAges: number[],
  infantSeating: InfantSeating
): string => {
  let flightSearch = "";
  flightSearch = `children:${numOfChildren + numOfInfants}`;
  flightSearch += childrenAges.length ? `[${childrenAges.join(";")}]` : "";
  flightSearch += `,adults:${numOfAdults}`;
  flightSearch += ",seniors:0";
  flightSearch += `,infantinlap:${infantSeating === InfantSeating.ON_LAP ? "Y" : "N"}`;

  return flightSearch;
};

const buildHotelSearch = (
  adultsPerRoom: number[],
  childrenPerRoom: string[],
  { numOfRooms, hotelSearch }: TravelersMetadata
): HotelSearch => {
  hotelSearch.rooms = numOfRooms.toString();
  hotelSearch.adults = adultsPerRoom.join(",");
  if (childrenPerRoom.length) {
    hotelSearch.children = childrenPerRoom.join(",");
  }

  return hotelSearch;
};

const buildHotwireHotelSearch = ({
  numOfAdults,
  numOfChildren,
  numOfInfants,
  numOfRooms,
  hotwireHotelSearch,
}: TravelersMetadata): HotelSearch => {
  hotwireHotelSearch.rooms = numOfRooms.toString();
  hotwireHotelSearch.adults = numOfAdults.toString();
  hotwireHotelSearch.children = (numOfChildren + numOfInfants).toString();

  return hotwireHotelSearch;
};

const buildCruiseSearch = (
  { numOfAdults, numOfChildren, cruiseSearch }: TravelersMetadata,
  childrenAges: number[]
): CruiseSearch => {
  cruiseSearch.numOfAdults = numOfAdults;
  cruiseSearch.numOfChildren = numOfChildren;
  if (childrenAges.length) {
    cruiseSearch.childAges = childrenAges.join(",");
  }

  return cruiseSearch;
};

const buildThirdSearch = (
  { numOfAdults, numOfChildren, thirdSearch }: TravelersMetadata,
  childrenAges: number[]
): ThirdSearch => {
  thirdSearch.numOfAdults = numOfAdults;
  thirdSearch.numOfChildren = numOfChildren;
  if (childrenAges.length) {
    thirdSearch.childAges = childrenAges.join(",");
  }

  return thirdSearch;
};

const buildGroundTransportSearch = ({
  numOfAdults,
  numOfChildren,
  numOfInfants,
  groundTransportSearch,
}: TravelersMetadata): GroundTransportSearch => {
  groundTransportSearch.numOfAdults = numOfAdults;
  groundTransportSearch.numOfChildren = numOfChildren;
  groundTransportSearch.numOfInfants = numOfInfants;

  return groundTransportSearch;
};

export const travelersMetadata = (travelers: Travelers): TravelersMetadata => {
  const adultsPerRoom: number[] = [];
  const childrenPerRoom: string[] = [];
  const metadata: TravelersMetadata = travelers.rooms.reduce(
    (data: TravelersMetadata, room: Room, roomIndex: number) => {
      data.numOfAdults += room.adults;
      data.numOfChildren += room.children.length;
      data.numOfInfants += room.infants.length;
      const childrenAges: number[] = [];
      let numOfChildrenAgeTwelveOrOlder = 0;
      for (const child of room.children) {
        childrenAges.push(child.age);
        childrenPerRoom.push(`${roomIndex + 1}_${child.age}`);
        numOfChildrenAgeTwelveOrOlder += child.age >= 12 ? 1 : 0;
        data.packageSearch[`childAges[${roomIndex + 1}][${childrenAges.length}]`] = child.age;
        data.hotwirePackageSearch[`childrenAgePerRoom${roomIndex}${childrenAges.length - 1}`] = child.age;
      }
      data.travelersAgeTwelveOrOlder += room.adults + numOfChildrenAgeTwelveOrOlder;
      for (const infant of room.infants) {
        childrenAges.push(infant.age);
        childrenPerRoom.push(`${roomIndex + 1}_${infant.age}`);
        data.packageSearch[`childAges[${roomIndex + 1}][${childrenAges.length}]`] = infant.age;
        data.hotwirePackageSearch[`childrenAgePerRoom${roomIndex}${childrenAges.length - 1}`] = infant.age;
      }
      adultsPerRoom.push(room.adults);
      data.packageSearch[`adultsPerRoom[${roomIndex + 1}]`] = room.adults;
      data.packageSearch[`childrenPerRoom[${roomIndex + 1}]`] = room.children.length + room.infants.length;
      data.flightSearch = buildFlightSearch(data, childrenAges, travelers.infantSeating);
      data.hotwireFlightSearch = buildHotwireFlightSearch(data);
      data.hotwirePackageSearch[`numAdultsPerRoom${roomIndex}`] = room.adults;
      data.hotwirePackageSearch[`numChildrenPerRoom${roomIndex}`] = room.children.length + room.infants.length;

      data.cruiseSearch = buildCruiseSearch(data, childrenAges);
      data.thirdSearch = buildThirdSearch(data, childrenAges);
      data.groundTransportSearch = buildGroundTransportSearch(data);

      return data;
    },
    {
      numOfAdults: 0,
      numOfChildren: 0,
      numOfInfants: 0,
      numOfRooms: 0,
      travelersAgeTwelveOrOlder: 0,
      flightSearch: "",
      hotwireFlightSearch: 0,
      hotelSearch: {
        rooms: "0",
        adults: "0",
      },
      hotwireHotelSearch: {
        rooms: "0",
        adults: "0",
      },
      packageSearch: {
        numberOfRooms: "0",
        infantsInSeats: "0",
      },
      hotwirePackageSearch: {
        numberOfRooms: "0",
        infantsInSeats: "0",
      },
      cruiseSearch: {
        numOfAdults: 1,
        numOfChildren: 0,
      },
      thirdSearch: {
        numOfAdults: 1,
        numOfChildren: 0,
      },
      groundTransportSearch: {
        numOfAdults: 1,
        numOfChildren: 0,
        numOfInfants: 0,
      },
    }
  );
  metadata.numOfRooms = travelers.rooms.length;
  metadata.hotelSearch = buildHotelSearch(adultsPerRoom, childrenPerRoom, metadata);
  metadata.hotwireHotelSearch = buildHotwireHotelSearch(metadata);
  metadata.packageSearch.numberOfRooms = metadata.numOfRooms.toString();
  metadata.hotwirePackageSearch.noOfRooms = metadata.numOfRooms.toString();
  metadata.packageSearch.infantsInSeats = travelers.infantSeating === InfantSeating.IN_SEAT ? "1" : "0";

  return metadata;
};

export const travelersSummary = (config: TravelersFieldConfig, rooms: Room[], numOfTravelers: number) => {
  const { formatText } = useLocalization();
  const { withRooms, roomsGuestsToken, travelersToken } = config;

  if (withRooms) {
    return formatText(roomsGuestsToken, rooms.length, numOfTravelers);
  }
  return formatText(travelersToken, numOfTravelers);
};
