export enum TRAVELERS_HIDDEN_INPUT_TYPE {
  FLIGHT_SEARCH = "FLIGHT_SEARCH",
  HOTWIRE_FLIGHT_SEARCH = "HOTWIRE_FLIGHT_SEARCH",
  HOTEL_SEARCH = "HOTEL_SEARCH",
  HOTWIRE_HOTEL_SEARCH = "HOTWIRE_HOTEL_SEARCH",
  PACKAGE_SEARCH = "PACKAGE_SEARCH",
  HOTWIRE_PACKAGE_SEARCH = "HOTWIRE_PACKAGE_SEARCH",
  CRUISE_SEARCH = "CRUISE_SEARCH",
  GROUND_TRANSPORT_SEARCH = "GROUND_TRANSPORT_SEARCH",
  CAR_SEARCH = "CAR_SEARCH",
  THIRD_SEARCH = "THIRD_SEARCH",
  NONE = "NONE",
}
