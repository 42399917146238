import * as React from "react";
import { toJS } from "mobx";
import { TravelersPicker } from "./TravelersPicker";
import { TravelersContentProps, Travelers } from "../typings";
import { LobErrorSummary } from "../../LobErrorSummary/LobErrorSummary";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkHeading } from "uitk-react-text";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { HotelWizardState } from "src/stores/wizard/state";
import { UitkSpacing } from "uitk-react-spacing";

export const TravelersContent = (props: TravelersContentProps) => {
  const roomRefs: React.RefObject<HTMLHeadingElement>[] = [];
  const { onChange, lobState, setInputsRefs } = props;
  const { validateTravelersField, validateInfantsPerTraveler, validateUnattendedInfantInLap, config } = lobState;
  const { formatText } = useLocalization();
  const [totalRoomRefs, setTotalRoomRefs] = React.useState<number>(0);
  const [travelers, setTravelers] = React.useState<Travelers>(toJS(props.travelers));
  const isTravelersValid = validateTravelersField!();
  const invalidToken = lobState.hotelTravelersInvalidKey || lobState.nonHotelTravelersInvalidKey || "";
  const showError = !isTravelersValid && invalidToken !== "";
  const [errorLocArgs, setErrorLocArgs] = React.useState<any>([]);

  const {
    invalidNumberOfInfantPerTravelerMessageToken,
    invalidUnattendedInfantOnLapMessageToken,
    maxBookableTravelers,
  } = props.config;

  const setAndSaveTravelers = (travelersData: Travelers) => {
    setTravelers(travelersData);
    onChange(travelersData);
  };

  React.useEffect(() => {
    const lastRoomRef = roomRefs[roomRefs.length - 1];

    if (!!lastRoomRef && !!lastRoomRef.current) {
      const headerElement = lastRoomRef.current.firstElementChild as HTMLHeadingElement;
      if (headerElement) headerElement.focus();
    }
  }, [totalRoomRefs]);

  React.useEffect(() => {
    //handles each error loc args depending on validations
    if (invalidToken) {
      if (
        validateInfantsPerTraveler &&
        !validateInfantsPerTraveler() &&
        invalidToken === invalidNumberOfInfantPerTravelerMessageToken
      ) {
        setErrorLocArgs([config.travelers.minInfantPerTraveler, config.travelers.minAdultAge]);
      } else if (
        validateUnattendedInfantInLap &&
        !validateUnattendedInfantInLap() &&
        invalidToken === invalidUnattendedInfantOnLapMessageToken
      ) {
        setErrorLocArgs([config.travelers.minAdultAge]);
      } else {
        setErrorLocArgs([maxBookableTravelers]);
      }
    }

    if (toJS(props.travelers) !== travelers && lobState instanceof HotelWizardState) {
      setTravelers(props.travelers);
    }
  }, [showError, invalidToken, props.travelers]);

  return (
    <div>
      <Viewport>
        <ViewSmall>
          <>
            {showError && (
              <UitkSpacing padding={{ inline: "one", blockstart: "two" }}>
                <div>
                  <UitkSpacing padding={{ blockstart: "two" }}>
                    <LobErrorSummary
                      locHeadingToken={invalidToken}
                      locHeadingArgs={errorLocArgs}
                      focusOnError={false}
                      setInputsRefs={setInputsRefs}
                    />
                  </UitkSpacing>
                </div>
              </UitkSpacing>
            )}
          </>
        </ViewSmall>
        <ViewLarge>
          <UitkSpacing padding={{ inline: "one", blockstart: "two" }}>
            <div>
              <UitkHeading tag="h3" size={5}>
                {formatText(props.config.dialogTitleToken)}
              </UitkHeading>
              {showError && (
                <UitkSpacing padding={{ blockstart: "two" }}>
                  <LobErrorSummary
                    locHeadingToken={invalidToken}
                    locHeadingArgs={errorLocArgs}
                    focusOnError={false}
                    setInputsRefs={setInputsRefs}
                  />
                </UitkSpacing>
              )}
            </div>
          </UitkSpacing>
        </ViewLarge>
      </Viewport>
      <TravelersPicker
        {...props}
        roomRefs={roomRefs}
        totalRoomRefs={totalRoomRefs}
        setTotalRoomRefs={setTotalRoomRefs}
        travelers={travelers}
        setTravelers={setAndSaveTravelers}
      />
    </div>
  );
};
